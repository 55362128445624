/*!
 * SNS JavaScript Library v1
 *
 * Copyright 2014 SNS
 */

import SimpleDialog2Ctrl from "~/components/jpbl/UI/dialog/simple_dialog_2/controller";
import { useDialogAdapter } from "~/composables/useDialogAdapter";
const { dialogAdapter } = useDialogAdapter();

export const SNS = {
  setLang : function(settins) {
    this.lang = settins;
  },
  l : function(f, v) {
    return this.lang[f][v];
  },
  sprintf : function () {
    var format = arguments[0];
    var args   = arguments;
    var i=1;
    return format.replace(/%s/g, function(){
      return args[i++];
    });
  },
  date : function() {
    var d = new Date();
    var month  = d.getMonth() + 1;
    var day    = d.getDate();
    if (month < 10) {month = "0" + month;}
    if (day < 10) {day = "0" + day;}
    return d.getFullYear()  + "/" + month + "/" + day;
  },
  getQuerystring : function(key, default_) {
    if(default_ == null) default_="";
    key = key.replace(/[\[]/,"\\\[").replace(/[\]]/,"\\\]");
    var regex = new RegExp("[\\?&]"+key+"=([^&#]*)");
    var qs = regex.exec(window.location.href);
    if(qs == null){
      return default_;
    }else{
      return qs[1];
    }
  },
  hashLength : function (array){
    var len = 0;
    for(var key in array){len++;}
    return len;
  },
  getPath : function() {
    var parts = window.location.pathname.split('/');
    return '/'+parts[1];
  },
  getNotice : function() {
  },

  showMessage : function(message, title) {
    var dialog = $('<div id="ui-confirm-dialog" title="'+title+'"></div>');
    var p = $('<p>'+message+'</p>');
    dialog.append(p);
    var btns = {};
    btns['OK'] = function(){$(this).dialog('close');response(false)};
    dialog.dialog({
      autoOpen: true,
      title: title,
      modal: true,
      draggable: false,
      resizable: false,
      width: 300,
      minHeight: 100,
      show: 'slide',
      hide: 'slide',
      dialogClass: 'confirm',
      buttons: btns,
      close: function(e){
        $('#ui-confirm-dialog').remove();
      }
    });
  },
  showMessageVue: function(message, title) {
    dialogAdapter.open(new SimpleDialog2Ctrl({
      headerText: title,
      buttonPromptHtml: `<div style="text-align:left;padding: 0px 10px;">${message}</div>`,
      buttons: {
        OK: {
          click: () => dialogAdapter.close(),
        },
      }
    }));
  },
  showMessageVue2: function(message, title) {
    dialogAdapter.open(new SimpleDialog2Ctrl({
      headerText: title,
      buttonPromptHtml: `${message}`,
      buttons: {
        OK: {
          click: () => dialogAdapter.close(),
        },
      }
    }));
  },
  showMessageWithButton: (message, title, buttons) => {
    const simpleD = new SimpleDialog2Ctrl(
        {
          mode: "button",
          headerText: title,
          headerClose: false,
          buttonPromptHtml: message,
          buttons: buttons,
        },
        () => dialogAdapter.close()
    );
    dialogAdapter.open(simpleD);
  },
  showError : function(message, title) {
    var dialog = $('<div id="ui-error-dialog" title="'+title+'"></div>');
    var p = $('<p>'+message+'</p>');
    dialog.append(p);
    dialog.dialog({
      autoOpen: true,
      title: title,
      modal: true,
      draggable: false,
      resizable: false,
      width: 300,
      minHeight: 100,
      show: 'slide',
      hide: 'slide',
      dialogClass: 'error',
      buttons: {},
      close: function(e){
        $('#ui-error-dialog').remove();
      }
    });
  },
  showErrorVue: function(message, title, withParent = 0) {
    dialogAdapter.open(new SimpleDialog2Ctrl({
      headerText: title,
      buttonPromptHtml: `<div style="text-align:left;padding: 0px 10px;">${message}</div>`,
      buttons: {
        OK: {
          click: () => dialogAdapter.close(withParent),
        },
      }
    }));
  },

  showConfirm : function(message, title, btnok, btncancel, response) {
    var dialog = $('<div id="ui-confirm-dialog" title="'+title+'"></div>');
    var p = $('<p>'+message+'</p>');
    dialog.append(p);
    var btns = {};
    btns[btnok] = function(){$(this).dialog('close');response(false)};
    btns[btncancel]  = function(){$(this).dialog('close');response(true)};
    dialog.dialog({
      autoOpen: true,
      title: title,
      modal: true,
      draggable: false,
      resizable: false,
      width: 300,
      minHeight: 100,
      show: 'slide',
      hide: 'slide',
      dialogClass: 'confirm',
      buttons: btns,
      close: function(e){
        $('#ui-confirm-dialog').remove();
      }
    });
  },
  showLogout : function() {
    var url_top = '../'
    var elm = $('<a>', { href: location.href } )[0];
    var ary_tmp = (elm.pathname).split('/');
    if (ary_tmp.length > 2) {
      url_top = '/' + ary_tmp[1];
    }

    var title = 'ログアウト';
    var dialog = $('<div id="ui-confirm-dialog" title="'+title+'"></div>');
    var p = $('<p>ログアウトしました。<br />自動的にTOPページへ遷移します。</p>');
    dialog.append(p);
    var btns = {};
    btns['OK'] = function(){$(this).dialog('close');};
    dialog.dialog({
      autoOpen: true,
      title: title,
      modal: true,
      draggable: false,
      resizable: false,
      width: 300,
      minHeight: 100,
      show: 'slide',
      hide: 'slide',
      dialogClass: 'confirm',
      buttons: btns,
      open: function(e){
        setTimeout(function(){location.replace( url_top );}, 3000);
      },
      close: function(e){
        $('#ui-confirm-dialog').remove();
        location.replace( url_top );
      }
    });
  },
  imageView : function(btn) {
    var path = $(btn).attr('data-path');
    var dialog = $('<div id="ui-image-view-dialog"></div>');
    var img = $('<img>');
    img.imagesLoaded(function(){
      var img = $(this.images[0].img);
      var width = img.width();
      var height = img.height();
      if(width > 500){
        height = height * (500 / width);
      }
      dialog.append(img);
      var a = $('<a href="javascript:void(0);">閉じる</a>');
      a.attr('onclick', '$(this).closest(".ui-dialog-content").dialog("close");');
      dialog.append(a);
      dialog.dialog({
        autoOpen: true,
        title: '',
        modal: true,
        draggable: false,
        resizable: false,
        width: 500,
        minHeight: height,
        show: 'slide',
        hide: 'slide',
        dialogClass: 'image-view',
        buttons: {},
        open: function(e){
        },
        close: function(e){
          $('#ui-image-view-dialog').remove();
        }
      });
    });
    img.attr('src', path);
  },
  textExpand : function(a) {
    var show = $(a).parent();
    var hide = show.parent().find('.text-expand-hide');
    var end = show.parent().find('.text-expand-end');
    end.css('display', 'none').remove();
    show.css('display', 'none').remove();
    hide.fadeIn(400, function(){
      $(this).find('img').each(function(){
        var img = $(this);
        SNS.refleshImgProtect(img);
      });
    }).css('display', 'inline');
  },
  nl2br : function(s) {
    s = s.replace(/(\r\n){3,}|\r{3,}|\n{3,}/g, "\n\n");
    s = s.replace(/\n/g, '<br>');
    return s;
  },
  imgProtect : function(img, callback) {
    img.addClass('prt');
    img.bind('contextmenu', function(e){return false;});
    var width = img.width();
    var height = img.height();
    var span = $('<span class="prt">');//$('<span class="prt">PROTECTED!</span>');
    span.css('cursor', img.css('cursor'));
    span.css('line-height', height+'px');
    span.bind('contextmenu', function(e){return false;});
    var wrap = $('<div class="prt">');
    wrap.attr('src', img.attr('src'));
    if(callback) wrap.click(callback);
    wrap.width(width);
    wrap.height(height);
    img.wrap(wrap);
    img.after(span);
  },
  refleshImgProtect : function(img) {
    var wrap = img.parent();
    if(!wrap.hasClass('prt')) return;
    var width = img.width();
    var height = img.height();
    wrap.width(width);
    wrap.height(height);
    img.next().css('line-height', height+'px');
  },
  createLink : function(e) {
    e.contents().each(function(){
      var self = $(this);
      if (self.get(0).nodeName === 'A') return;
      if(this.nodeType === 3){
        var url = SNS.getPath()+'/l';
        self.replaceWith(self.text().replace(/((http|https|ftp):\/\/[\w?=&.\/-;#~%-]+(?![\w\s?&.\/;#~%"=-]*>))/g, '<a href="'+url+'?url=$1" target="_blank">$1</a>'));//"
      }else if(self.text().length > 0){
        if(self.contents().length > 0){
          SNS.createLink(self);
        }
      }
    });
  },
  isIE8 : function(){
    var userAgent = window.navigator.userAgent.toLowerCase();
    var appVersion = window.navigator.appVersion.toLowerCase();
    if (userAgent.indexOf("msie") != -1) {
      if (appVersion.indexOf("msie 8.") != -1) {
        return true;
      }
    }
    return false;
  },
  isIE7 : function(){
    var userAgent = window.navigator.userAgent.toLowerCase();
    var appVersion = window.navigator.appVersion.toLowerCase();
    if (userAgent.indexOf("msie") != -1) {
      if (appVersion.indexOf("msie 7.") != -1) {
        return true;
      }
    }
    return false;
  },
  replace_domain : function(base_url, new_prefix, old_prefix){
    if (new_prefix == '') return base_url;
    if (base_url.indexOf(old_prefix) == -1 ) return base_url;
    return new_prefix + base_url.substring(old_prefix.length);
  },
  isStandalone : function() {
    if (document.referrer.startsWith("android-app://")) {
      return true;
    } else if ( "standalone" in window.navigator && window.navigator.standalone) {
      return true;
    }

    return false;
  },
  isAndroid : function(){
    return (navigator.userAgent.indexOf("Android") != -1);
  },
  androidVersion : function(){
    let ua = navigator.userAgent;
    let version = '';
    if(ua.indexOf("Android") >= 0){
      version = parseFloat(ua.slice(ua.indexOf("Android")+8));
    }
    return version;
  },
  isiOS : function(){
    let ua = navigator.userAgent;
    if(/iPhone/.test(ua)) {
      return true;
    }else if(/iPad/.test(ua)) {
      return true;
    }else if(/iPod/.test(ua)) {
      return true;
    }else{
      return false;
    }
  },
  iosVersion : function(){
    if(!origin_app.isiOS()) return false;
    let ua = navigator.userAgent;
    let version = '';
    if(/iPhone/.test(ua)) {
      ua.match(/iPhone OS (\w+){1,3}/g);
      version = (RegExp.$1.replace(/_/g, '')+'00').slice(0,3);
    }else if(/iPad/.test(ua)) {
      ua.match(/CPU OS (\w+){1,3}/g);
      version = (RegExp.$1.replace(/_/g, '')+'00').slice(0,3);
    }else{
      version = false;
    }
    return version;
  },

}
